<template>
  <div
    class="grid size-full gap-spacing-s overflow-y-auto px-spacing-xs py-spacing-s sm:p-spacing-m md:grid-cols-12 lg:gap-spacing-m"
  >
    <div class="md:col-span-4 lg:col-span-3">
      <s-sheet
        class="flex w-full flex-col justify-center bg-color-neutral-150 p-spacing-s"
        :elevation="SElevationTokens.null"
      >
        <h2 class="h2-typography w-full pb-spacing-xs">
          {{ translate('Dashboard.Kpi.Section.Title') }}
        </h2>
        <div class="mb-spacing-s">
          <s-icon-kpi
            :title="translate('Dashboard.Kpi.Section.Effectiveness.Title')"
            :value="percentageAgent"
            unit="%"
            :subtitle="contentFormatDate"
          >
            <template #icon>
              <shield-logo />
            </template>
          </s-icon-kpi>
        </div>
        <s-sheet :key="language" class="bg-color-white p-spacing-s">
          <h3 class="h3-typography w-full py-spacing-xs">
            {{ translate('Dashboard.Performance.Graphic.Section.Title') }}
          </h3>
          <performance-chart
            v-if="!isLoading"
            :sections="performanceLeads"
            @section-click="handlePerformanceSectionClick"
          />
          <div v-else class="flex h-[26rem] items-center justify-center">
            <s-progress-circular />
          </div>
        </s-sheet>
      </s-sheet>
    </div>
    <div class="h-full md:col-span-8 lg:col-span-9">
      <div>
        <h2 class="h2-typography w-full pb-spacing-xs pt-spacing-s">
          {{ translate('Dashboard.Leads.Section.Title') }}
        </h2>
        <div class="grid gap-spacing-s md:grid-cols-12 lg:gap-spacing-m">
          <div class="flex flex-col gap-spacing-s md:col-span-3 lg:col-span-2">
            <s-sheet class="w-full bg-color-white">
              <h3 class="h3-typography w-ful px-spacing-s pb-spacing-xxs pt-spacing-s">
                {{ translate('Dashboard.Leads.Section.New') }}
              </h3>
              <div class="flex items-center justify-center pb-spacing-xs">
                <s-circle-kpi
                  class="cursor-pointer"
                  :title="translate('Dashboard.Leads.Section.New.NoAssigned')"
                  circle-color="--color-terciary-500"
                  :value="agentTotalCreated"
                  @click="handleClickCreatedKpi"
                />
              </div>
            </s-sheet>
            <s-sheet class="h-[30.25rem] w-full bg-color-white p-spacing-s">
              <h3 class="h3-typography w-full px-spacing-s pb-spacing-m">
                {{ translate('Dashboard.Leads.Section.MyLeads') }}
              </h3>
              <div class="flex h-full flex-col items-center justify-center gap-spacing-xl">
                <s-circle-kpi
                  class="cursor-pointer"
                  :title="translate('Dashboard.Leads.Section.MyLeads.Assigned')"
                  :value="agentTotalAssigned"
                  circle-color="--color-primary-300"
                  @click="handleClickAssignedKpi"
                />
                <s-circle-kpi
                  class="cursor-pointer"
                  :title="translate('Dashboard.Leads.Section.MyLeads.Contacted')"
                  :value="agentTotalContacted"
                  circle-color="--color-primary-500"
                  @click="handleClickContactedKpi"
                />
              </div>
            </s-sheet>
          </div>
          <div class="md:col-span-9 lg:col-span-5">
            <leads-to-expire
              class="h-full"
              :title="translate('Dashboard.Leads.ExpirationList.Title')"
              :leads="dashboardStore.leadsToExpire.leads"
              :is-loading="isLoading"
              color-chip="error"
              :show-see-more="showSeeMore"
              :is-fetching-more="isFetchingMoreDueExpireLeads"
              @click:lead="handleLeadClick"
              @click:see-more="handleSeeMoreExpireLeadsClick"
            />
          </div>
          <div class="md:col-span-12 lg:col-span-5">
            <reactivate-leads
              :title="translate('Dashboard.Leads.ReactivationList.Title')"
              class="h-full"
              :leads="dashboardStore.leadsReactivate"
              :is-loading="isLoading"
              color-chip="warning"
              @click:lead="handleLeadClick"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LeadsToExpire from '@/components/Molecules/DashboardLeadsCard/DashboardLeadsCard.vue'
import ReactivateLeads from '@/components/Molecules/DashboardLeadsCard/DashboardLeadsCard.vue'
import { SElevationTokens, SProgressCircular, SSheet } from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, onMounted, ref, watch } from 'vue'
import { useDashboardStore } from '@/store/Dashboard'
import { useDealersStore } from '@/store'
import type { IGetLeadsQueryResult } from 'lead-tray-api-ts'
import { useRouter } from 'vue-router'
import SIconKpi from '@/components/Atoms/SIconKpi/SIconKpi.vue'
import dayjs from '@/utils/dayjs'
import ShieldLogo from '@/components/Atoms/Logos/ShieldLogo.vue'
import SCircleKpi from '@/components/Atoms/SCircleKpi/SCircleKpi.vue'
import { useLeadsStore, useUserStore } from '@/store'
import { createFilter } from '@/utils'
import { LeadsFiltersType } from '@/store/Leads/types'
import type { UserProfile } from 'sima-suite-authentication'
import PerformanceChart from '@/components/Atoms/PerformanceChart/PerformanceChart.vue'
import { useBreadcrumb } from '@/composables'

const router = useRouter()
const dashboardStore = useDashboardStore()
const leadsStore = useLeadsStore()
const userStore = useUserStore()
const dealersStore = useDealersStore()
const leadsToExpireCurrentPage = ref(1)

const { toolbar } = useBreadcrumb()

const { translate, language } = useTranslator()

toolbar.title = translate('App.Menu.Section.Dashboard')
toolbar.breadcrumb.isEnable = false

const isLoading = ref(false)
const isFetchingMoreDueExpireLeads = ref(false)

const handlePerformanceSectionClick = (section: any) => {
  leadsStore.filters = []
  const user = userStore.user as UserProfile
  const filterByUser = createFilter('agentName', user.name!, LeadsFiltersType.BY_USER)
  const { seriesName, dataIndex } = section
  if (
    seriesName === translate('Dashboard.Performance.Graphic.Section.CloseWithAppointment.Label') &&
    dataIndex === 0
  ) {
    leadsStore.addFilter(filterByUser)
    const filterByStatus = createFilter('leadStatusId', '4', LeadsFiltersType.BY_STATUS)
    leadsStore.addFilter(filterByStatus)
  }
  if (
    seriesName ===
      translate('Dashboard.Performance.Graphic.Section.CloseWithoutAppointment.Label') &&
    dataIndex === 0
  ) {
    leadsStore.addFilter(filterByUser)
    const filterByStatus = createFilter('leadStatusId', '6', LeadsFiltersType.BY_STATUS)
    leadsStore.addFilter(filterByStatus)
  }
  if (
    seriesName === translate('Dashboard.Performance.Graphic.Section.Expired.Label') &&
    dataIndex === 0
  ) {
    leadsStore.addFilter(filterByUser)
    const filterByStatus = createFilter('leadStatusId', '5', LeadsFiltersType.BY_STATUS)
    leadsStore.addFilter(filterByStatus)
  }
  if (
    seriesName === translate('Dashboard.Performance.Graphic.Section.CloseWithAppointment.Label') &&
    dataIndex === 1
  ) {
    const filterByStatus = createFilter('leadStatusId', '4', LeadsFiltersType.BY_STATUS)
    leadsStore.addFilter(filterByStatus)
  }
  if (
    seriesName ===
      translate('Dashboard.Performance.Graphic.Section.CloseWithoutAppointment.Label') &&
    dataIndex === 1
  ) {
    const filterByStatus = createFilter('leadStatusId', '6', LeadsFiltersType.BY_STATUS)
    leadsStore.addFilter(filterByStatus)
  }
  if (
    seriesName === translate('Dashboard.Performance.Graphic.Section.Expired.Label') &&
    dataIndex === 1
  ) {
    const filterByStatus = createFilter('leadStatusId', '5', LeadsFiltersType.BY_STATUS)
    leadsStore.addFilter(filterByStatus)
  }
  setAllDealers()
  router.push({ name: 'leadList' })
}

const getDashboardInfo = async () => {
  isLoading.value = true
  try {
    await dashboardStore.getLeadsToExpire(leadsToExpireCurrentPage.value)
    await dashboardStore.getEffectiveness()
    await dashboardStore.getAgentStatistics()
    await dashboardStore.getReactivateLeads()
  } finally {
    isLoading.value = false
  }
}

const showSeeMore = computed(() => {
  return (
    dashboardStore.leadsToExpire.totalPages! > 1 &&
    dashboardStore.leadsToExpire.pageNumber! < dashboardStore.leadsToExpire.totalPages!
  )
})

onMounted(async () => {
  dashboardStore.leadsToExpire.leads = []
  dashboardStore.leadsToExpire.totalPages = 0
  dashboardStore.leadsToExpire.pageNumber = 0
  await getDashboardInfo()
})

const handleLeadClick = (lead: IGetLeadsQueryResult) => {
  router.push({ name: 'leadDetails', params: { id: lead.id } })
}

const handleSeeMoreExpireLeadsClick = async () => {
  leadsToExpireCurrentPage.value += 1
  isFetchingMoreDueExpireLeads.value = true
  try {
    await dashboardStore.getLeadsToExpire(leadsToExpireCurrentPage.value)
  } finally {
    isFetchingMoreDueExpireLeads.value = false
  }
}

const percentageAgent = computed(() => {
  return Math.round(dashboardStore.agentPerformance.scheduledLeadsPercentage!)
})

const contentFormatDate = computed(() => {
  return dayjs(new Date()).locale(language.value).format('LL')
})

const agentTotalAssigned = computed(() => {
  return dashboardStore.agentStatistics.totalAssigned!
})

const agentTotalContacted = computed(() => {
  return dashboardStore.agentStatistics.totalContacted!
})

const agentTotalCreated = computed(() => {
  return dashboardStore.agentStatistics.totalCreated!
})

const setAllDealers = () => {
  dealersStore.dealersList.forEach((dealer) => {
    dealer.isSelected = true
  })
}

const performanceLeads = computed(() => {
  return dashboardStore.performance
})

const handleClickAssignedKpi = () => {
  leadsStore.filters = []
  const user = userStore.user as UserProfile
  const filterByUser = createFilter('agentName', user.name!, LeadsFiltersType.BY_USER)
  leadsStore.addFilter(filterByUser)
  const filterByStatus = createFilter('leadStatusId', '1', LeadsFiltersType.BY_STATUS)
  leadsStore.addFilter(filterByStatus)
  setAllDealers()
  router.push({ name: 'leadList' })
}

const handleClickContactedKpi = () => {
  leadsStore.filters = []
  const user = userStore.user as UserProfile
  const filterByUser = createFilter('agentName', user.name!, LeadsFiltersType.BY_USER)
  leadsStore.addFilter(filterByUser)
  const filterByStatus = createFilter('leadStatusId', '2', LeadsFiltersType.BY_STATUS)
  leadsStore.addFilter(filterByStatus)
  setAllDealers()
  router.push({ name: 'leadList' })
}

const handleClickCreatedKpi = () => {
  leadsStore.filters = []
  const filterByStatus = createFilter('leadStatusId', '0', LeadsFiltersType.BY_STATUS)
  leadsStore.addFilter(filterByStatus)
  setAllDealers()
  router.push({ name: 'leadList' })
}

watch(
  () => language.value,
  () => {
    toolbar.title = translate('App.Menu.Section.Dashboard')
  }
)
</script>
