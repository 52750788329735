<template>
  <div class="size-full p-spacing-xs pt-spacing-s">
    <div v-if="isLoading" class="flex h-full w-full items-center justify-center">
      <s-progress-circular :is-active="isLoading" />
    </div>
    <s-custom-list v-else :items="items">
      <div class="pb-spacing-m">
        <template v-for="lead in items" :key="lead.id">
          <s-list-item
            :overline="getOverlineText(lead)"
            :primary-text="lead.topic ? translate(`Lead.Topic.${lead.topic}`) : ''"
            :secondary-text="lead.vehicleModel"
            @item-click="handleItemClick(lead)"
          >
            <template #prepend>
              <img
                :src="getBrandLogo(lead.brand!)"
                :alt="getNameBrand(lead.brand!).text"
                class="mr-spacing-s h-6 w-6 rounded-full"
              />
            </template>
            <template #append>
              <span
                class="b3-typography"
                :class="[
                  dayjs(lead.expirationDate!).diff(new Date(), 'day') < 1
                    ? 'text-color-error-500'
                    : 'text-color-neutral-300'
                ]"
              >
                {{ getRemainingTime(lead.expirationDate!, language) }}
              </span>
            </template>
          </s-list-item>
        </template>
      </div>
    </s-custom-list>
  </div>
</template>

<script setup lang="ts">
import { SCustomList, SListItem, SProgressCircular } from 'sima-suite-components-vue3'
import { type IGetLeadsQueryResult } from 'lead-tray-api-ts'
import { useTranslator } from 'sima-suite-translator-vue3'
import { BRANDLOGOS, getRemainingTime } from '@/utils'
import dayjs from '@/utils/dayjs.ts'

const { translate, language } = useTranslator()

type DashboardLeadListProps = {
  items: IGetLeadsQueryResult[]
  colorChip: string
  isLoading: boolean
}

const emit = defineEmits<{
  (e: 'itemClick', lead: IGetLeadsQueryResult): void
}>()

const handleItemClick = (lead: IGetLeadsQueryResult) => {
  emit('itemClick', lead)
}

const getOverlineText = (lead: IGetLeadsQueryResult) => {
  return lead.source
    ? `${lead.source} - ${lead.customerSalutationTitle} ${lead.customerFirstName} ${lead.customerLastName}`
    : `${lead.customerSalutationTitle} ${lead.customerFirstName} ${lead.customerLastName}`
}

const getBrandLogo = (brand: string) => {
  return BRANDLOGOS[brand]
}

const getNameBrand = (brand: string) => {
  return {
    text: translate(`App.CarBrand.${brand}`)
  }
}

defineProps<DashboardLeadListProps>()
</script>
