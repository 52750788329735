<template>
  <s-sheet
    :elevation="SElevationTokens.s"
    :rounded="SRoundedTokens.mr"
    class="relative bg-color-white p-spacing-s"
  >
    <div class="pb-spacing-xs">
      <s-main-toolbar :title="title" />
    </div>
    <div v-if="leads.length">
      <div ref="listContainer" class="h-full max-h-[36rem] overflow-y-auto">
        <dashboard-lead-list
          :items="leads"
          :color-chip="colorChip"
          :is-loading="isLoading"
          @item-click="handleItemClick"
        />
      </div>
      <div v-if="showSeeMore" class="absolute bottom-spacing-s w-full bg-color-white">
        <s-button
          :variant="SButtonVariant.outlined"
          :text="translate('Dashboard.Leads.ExpirationList.SeeMore')"
          :is-loading="isFetchingMore"
          @click="handleSeeMoreClick"
        />
      </div>
    </div>
    <div v-else class="flex h-full items-center justify-center">
      <s-visual-context
        :title="translate('Dashboard.Inbox.Clean.Title')"
        :src="image.src"
        :alt="image.alt"
        :subtitle="translate('Dashboard.Inbox.Clean.Content')"
      />
    </div>
  </s-sheet>
</template>

<script setup lang="ts">
import {
  SElevationTokens,
  SRoundedTokens,
  SSheet,
  SMainToolbar,
  SVisualContext,
  SButton,
  SButtonVariant
} from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { type IGetLeadsQueryResult } from 'lead-tray-api-ts'
import CleanInboxLogo from '@/assets/CleanInboxLogo.svg'
import { computed, onBeforeUnmount, ref } from 'vue'
import { watch } from 'vue'

import DashboardLeadList from '@/components/Molecules/DashboardLeadList/DashboardLeadList.vue'

const image = computed(() => {
  return { src: CleanInboxLogo, alt: translate('Dashboard.Inbox.Clean.Title') }
})

type leadsCardProps = {
  leads: IGetLeadsQueryResult[]
  title: string
  colorChip: string
  isLoading: boolean
  showSeeMore?: boolean
  isFetchingMore?: boolean
}

const { showSeeMore = false, isFetchingMore = false, leads } = defineProps<leadsCardProps>()

const { translate } = useTranslator()

const emit = defineEmits<{
  (e: 'click:lead', lead: IGetLeadsQueryResult): void
  (e: 'click:see-more'): void
}>()
const handleItemClick = (lead: IGetLeadsQueryResult) => {
  emit('click:lead', lead)
}

const handleSeeMoreClick = () => {
  emit('click:see-more')
}

const listContainer = ref<HTMLElement | null>(null)
const timeoutId = ref<NodeJS.Timeout | null>(null)
watch(
  () => leads,
  (newLeads, oldLeads) => {
    if (newLeads.length > oldLeads.length && listContainer.value) {
      timeoutId.value = setTimeout(() => {
        listContainer.value?.scrollTo({ top: listContainer.value.scrollHeight, behavior: 'smooth' })
      }, 0)
    }
  }
)

onBeforeUnmount(() => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value)
  }
})
</script>
